import React from 'react';
import {TouchpointResultType} from "../../../store/models/TouchpointEnums";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import TouchpointTab from "./TouchpointTab";
import {TFunction, useTranslation} from "react-i18next";
import {Field} from "formik";
import Contact from "../../../store/models/Contact";
import {ToDo, ToDoType} from "../../../store/models/ToDo";
import {Note, NoteType, ProductTypesPresented, VisitingReportResults} from "../../../store/models/Note";
import {thunkAddNote} from "../../../store/actions/NoteActions";
import {thunkAddToDo, thunkGetToDos} from "../../../store/actions/ToDoActions";
import {useDispatch} from "react-redux";
import {DatePicker, DateTimePicker} from "formik-material-ui-pickers";
import {useHistory} from "react-router-dom";
import {yesNoOptions} from "../../mappedSelectOptions";
import {startOfDay} from "date-fns";
import * as Yup from "yup";

interface VisitingReportTabProps {
    touchpointResultType: TouchpointResultType;
    contact?: Contact;
    emailPickerMails: string[];
}


export function fetchVisitingReportResultOptions(t: TFunction<"translation", undefined>) {
    const visitingReportResultOptions =
        [
            VisitingReportResults.OFFER,
            VisitingReportResults.SAMPLE_SENT,
            VisitingReportResults.ORDER,
            VisitingReportResults.NO_INTEREST,
            VisitingReportResults.NEW_APPOINTMENT,
            VisitingReportResults.NOT_REACHED,
            VisitingReportResults.MEETING_CANCELED,
            VisitingReportResults.AFTERSALES
        ].map(vRR => ({
            label: t('visitingReportTab.visitingReportResultOptions.' + vRR),
            value: vRR
        }))
    return visitingReportResultOptions;
}

export const orderProductTypes = [
    ProductTypesPresented.BAG_PRODUCTS,
    ProductTypesPresented.DIGITAL_PRODUCTS,
    ProductTypesPresented.FASHION,
    ProductTypesPresented.ESO,
    ProductTypesPresented.OFFICE_EQUIPMENT,
];

export function fetchOrderProductTypesOptions(t: TFunction<"translation", undefined>) {
    const orderProductTypesOptions = orderProductTypes.map(pTP => ({
        label: t('visitingReportTab.OrderProductTypes.' + pTP),
        key: pTP
    }))
    return orderProductTypesOptions;
}

export default function VisitingReportTab({touchpointResultType, contact}: VisitingReportTabProps) {
    const {t} = useTranslation();
    const visitingReportResultOptions = fetchVisitingReportResultOptions(t);

    const orderProductTypesOptions = fetchOrderProductTypesOptions(t);

    const orderProductValues: any = {};
    orderProductTypesOptions.forEach(value => orderProductValues[value.key] = false)


    const history = useHistory();
    const dispatch = useDispatch();


    return (<TouchpointTab
        tabIndex={TouchpointResultType.Visiting_Report}
        activeTab={touchpointResultType}
        validationSchema={Yup.object().shape({
            visitingReportResult: Yup.string()
                .required(t('validation.required')),
            forDate: Yup.date().required(t('validation.required')).nullable(),
        })}
        validate={async (values) => {
            if (values.startDate && !values.withTime) {
                values.startDate = startOfDay(values.startDate);
            }
        }}

        handleSubmit={async (values) => {
            if (values.startDate) {
                if (!values.withTime) {
                    values.startDate = startOfDay(values.startDate);
                }
                const newToDo: ToDo = {
                    admID: "",
                    created: new Date(),
                    forCustomer: contact?.stContactID,
                    text: "" + contact?.companyLine1,
                    dueOn: values.startDate,
                    type: ToDoType.FollowUp,
                };
                const followUpNote: Note = {
                    contactNumber: contact?.stContactID || '',
                    created: new Date(),
                    private: false,
                    text: t('contactDetailTouchpointTab.followUpNoteDefaultValue'),
                    type: NoteType.TouchpointResult,
                    meetingDetails: {
                        meetingStartDate: values.startDate,
                        todoType: ToDoType.FollowUp
                    },
                    touchpointResultType: TouchpointResultType.Follow_Up
                }
                await dispatch(thunkAddNote(followUpNote));
                await dispatch(thunkAddToDo(newToDo))
            }

            let text = ''
            if (values.note) {
                text = values.note
            } else {
                let resultOption = visitingReportResultOptions.find(v => v.value === values.visitingReportResult);
                if (resultOption) {
                    text = resultOption.label
                }
            }
            const newNote: Note = {
                contactNumber: contact?.stContactID || '',
                created: new Date(),
                private: false,
                text,
                type: NoteType.VisitingReport,
                forDate: values.forDate ? values.forDate : undefined,
                touchpointResultType,
                visitingReportProps: {
                    gotRecommendations: values.gotRecommendations,
                    productTypesPresented: orderProductTypes.filter(value => values.orderProductValues[value]),
                    visitResult: values.visitingReportResult
                }
            }
            await dispatch(thunkAddNote(newNote));
            await dispatch(thunkGetToDos());
            history.goBack();
        }}
        initialValues={{
            note: t('contactDetailTouchpointTab.orderNoteDefaultValue'),
            orderProductValues,
            startDate: null,
            visitingReportResult: '',
            forDate: null,
            gotRecommendations: 'false',
            withTime: false
        }}
    >
        {((values, handleChange, setFieldValue, isSubmitting, errors, touched) =>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>{t('contactDetailTouchpointTab.visitingReportResultLbl')}</InputLabel>
                            <Select
                                name="visitingReportResult"
                                value={values.visitingReportResult}
                                onChange={handleChange}
                                error={!!errors.visitingReportResult && touched.visitingReportResult}
                            >
                                {visitingReportResultOptions.map(sO => <MenuItem
                                    key={'visitingReportResultOption' + sO.label}
                                    value={sO.value}>{sO.label}</MenuItem>)}
                            </Select>
                            {(errors.visitingReportResult && touched.visitingReportResult) ?
                                <FormHelperText>{errors.visitingReportResult}</FormHelperText>
                                : null}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>{t('visitingReportTab.gotRecommendationsLbl')}</InputLabel>
                            <Select
                                name={'gotRecommendations'}
                                value={values.gotRecommendations}
                                onChange={handleChange}
                            >
                                {yesNoOptions().map(aT => <MenuItem
                                    key={'gotRecommendations' + aT.label}
                                    value={aT.value}>&nbsp;{aT.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            component={DatePicker}
                            inputVariant={"outlined"}
                            name="forDate"
                            value={values.forDate}
                            required
                            label={t('contactDetailTouchpointTab.formForDateLbl')}
                            format={'dd.MM.yyyy'}
                            ampm={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup row>
                            {orderProductTypesOptions.map(oPT =>
                                <FormControlLabel
                                    key={oPT.key}
                                    control={<Checkbox checked={values.orderProductValues[oPT.key]}
                                                       onChange={handleChange} color="primary"
                                                       name={'orderProductValues.' + oPT.key}/>}
                                    label={oPT.label}
                                />
                            )}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                multiline={true}
                                rows={3}
                                variant={"outlined"}
                                name="note"
                                label={t('contactDetailTouchpointTab.noteLbl')}
                                value={values.note}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"h6"}>{t('contactDetailTouchpointTab.followUp')}</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.withTime}
                                    onChange={handleChange}
                                    name="withTime"
                                    color="primary"
                                />
                            }
                            label={t('contactDetailTouchpointTab.followUpWithTime')}
                        />
                        <br/>
                        {values.withTime ?
                            <Field
                                component={DateTimePicker}
                                inputVariant={"outlined"}
                                name="startDate"
                                value={values.startDate}
                                label={t('contactDetailTouchpointTab.formStartLbl')}
                                format={'dd.MM.yyyy HH:mm'}
                                ampm={false}
                                required
                            />
                            :
                            <Field
                                component={DatePicker}
                                inputVariant={"outlined"}
                                name="startDate"
                                value={values.startDate}
                                label={t('contactDetailTouchpointTab.formStartLbl')}
                                format={'dd.MM.yyyy'}
                                ampm={false}
                                required
                            />
                        }


                    </Grid>
                    <Grid item xs={12}>
                        <Button disabled={isSubmitting} variant={"contained"} color={"primary"}
                                type={"submit"}>{t('common.saveBtn')}</Button>
                    </Grid>
                </Grid>
        )}
    </TouchpointTab>)
}
